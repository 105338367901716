<template>
    <div v-bind:class="{'offset-top': show_navbar}" id="app" class="container-fluid">

      <div v-if="logged_out" style="text-align: center; margin-top: 50px">
        <p>You have logged out from <b>DAMAS Drive</b>.</p>
        <p><a href="/">Click here to log back in.</a></p>
      </div>

      <div v-if="invalid_credentials_error" id="invalid_credentials_error" style="display:table;">
        <div style="display:table-cell;vertical-align:middle">
            <div>Invalid credentials.</div>
            <a href="/">Click here to refresh.</a>
        </div>
      </div>

      <div v-if="!logged_out && !invalid_credentials_error && (redirecting_to_auth_url || loading_aggregations || !user)" id="loading" style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            <span class="sr-only">Loading...</span>
        </div>
      </div>

      <!-- Navbar -->

      <nav v-if="show_navbar" class="navbar navbar-expand-lg fixed-top navbar-light shadow-sm bg-white py-1 pl-0">
        <span class="navbar-brand">
          <img src="/static/images/wnd_sigfox_logo.png" class="float-left" title="WND Group - Sigfox Operator" height="35px" alt="WND Group - Sigfox Operator">
        </span>
        
        <!-- App Store / Google Play badges -->

        <div class="d-none d-lg-block ml-auto">
          <ul class="navbar-nav">
              <li class="nav-item">
                <a target="_blank" href='https://apps.apple.com/gb/app/damas-drive/id1451715393'><img height="40px" alt='Get it on App Store' src='/static/images/app_store_badge.svg'/></a>
              </li>
              <li class="nav-item">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=io.wndgroup.damasdrive">
                  <img height="40px" alt="Get it on Google Play" src="/static/images/google-play-badge.png" />
                </a>
              </li>
          </ul>
        </div>

        <span class="navbar-brand ml-auto">
          <img src="/static/images/logo.png" class="float-right" title="DAMAS Drive" height="35px" alt="DAMAS Drive">
        </span>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_items" aria-controls="navbar_items" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div v-if="user" class="collapse navbar-collapse flex-grow-0" id="navbar_items">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="/user_manual"><img src="/static/images/help.png" height="22px" class="ml-3" alt="Help" title="Help"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" v-on:click.once="logOut()"><img src="/static/images/logout.png" height="20px" class="ml-3" alt="Log out" title="Log out"></a>
            </li>
          </ul>
        </div>
      </nav>

      <GoogleMap v-if="!logged_out && !invalid_credentials_error && user"
        :aggregations="aggregations"
        :base_stations="base_stations"
        :base_station_filter="base_station_filter"
        :country_prop="Object.keys(country_settings)[0]"
        :country_settings="country_settings"
        :with_sigfox_delta="user.can_view_sigfox_delta"
      />
    </div>

</template>

<script>
import GoogleMap from './components/GoogleMap.vue'
import { EventBus } from './event-bus.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'App',
  components: { GoogleMap },
  data () {
    return {
      show_navbar: true,
      invalid_credentials_error: false,
      user: null,
      country: 'brasil',
      aggregations: [],
      base_stations: [],
      base_station_filter: null,
      loading_aggregations: false,
      redirecting_to_auth_url: false,
      logged_out: false,
    }
  },
  methods: {
    fetch_aggregations: function(country) {
      var vm = this;
      this.loading_aggregations = true;
      axios.get('/aggregations/' + country)
      .then(function(response){
          var aggregations_json = response.data;
          vm.aggregations = aggregations_json;
      }).catch(function(error) {
          if (error.response && error.response.status == 403) { 
              vm.redirect_to_auth_url();
          } else {
              vm.loading_aggregations = false;
          }
      })
    },
    loading_aggregations_done: function(){
      this.loading_aggregations = false;
    },
    fetch_base_stations: function(country) {
      var vm = this;
      axios.get('/base_stations/' + country)
      .then(function(response){
          var base_stations_json = response.data;
          vm.base_stations = base_stations_json;
      }).catch(function(error) {
          if (error.response && error.response.status == 403) {
              vm.redirect_to_auth_url();
          } else {
              vm.loading_aggregations = false;
          }
      })
    },
    change_country: function(country) {
        this.fetch_aggregations(country);
        if (this.user.can_view_base_stations) {
          this.fetch_base_stations(country);
        }
    },
    redirect_to_auth_url: function() {
      if (!this.redirecting_to_auth_url) {
        this.redirecting_to_auth_url = true;
        window.location.href = '/oidc/authenticate';
      }
    },
    logOut() { 
      var csrftoken = Cookies.get('csrftoken');
      var vm = this;
      axios.post('/user/logout', null, {headers: { 'X-CSRFTOKEN': csrftoken }})
      .then(function(response){
        //vm.logged_out = true;
        //vm.user = null;
        window.location.href = response.data.redirect_to;
      }).catch(function(error) {
        if (error.response && error.response.status == 403) { 
            vm.redirect_to_auth_url();
        }
      });
    }
  },
  created: function () {

      if ("undefined" !== navigator && "undefined" !== navigator.userAgent && navigator.userAgent.indexOf("wv") !== -1) {
        // Android WebKitView
        this.show_navbar = false
      }

      if (this.$route.query.hide_navbar == "true") {
        Cookies.set("hide_navbar", true);
      } else if (this.$route.query.hide_navbar == "false") {
        Cookies.remove("hide_navbar");
      }

      if (Cookies.get("hide_navbar")) {
        this.show_navbar = false;
      }

      if (this.$route.query.error == "invalid_credentials") {
        this.invalid_credentials_error = true;
        return;
      }

      var vm = this;
      axios.get('/user/').then(function(response){
          var user = response.data;
          vm.user = user;
          vm.country_settings = user.country_settings;
          EventBus.$on('fetch_aggregations', vm.fetch_aggregations);
          if (user.can_view_base_stations) {
            EventBus.$on('fetch_base_stations', vm.fetch_base_stations);
          }
          EventBus.$on('loading_aggregations_done', vm.loading_aggregations_done);
          EventBus.$on('change_country', vm.change_country);
      }).catch(function(error) {
          if (error.response && error.response.status == 403) {
            vm.redirect_to_auth_url();
          }
      });
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.offset-top {
  padding-top: 55px;
}
.container-fluid {
    height: 100%;
}
#invalid_credentials_error {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(255,255,255,0.8);
  line-height: 100px;
  color: red;
}
#loading {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(255,255,255,0.8);
  line-height: 100px;
}
::-webkit-scrollbar {
-webkit-appearance: none;
width: 7px;
}
::-webkit-scrollbar-thumb {
border-radius: 4px;
background-color: rgba(0,0,0,.5);
box-shadow: 0 0 1px rgba(255,255,255,.5);
}
</style>
