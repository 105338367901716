<template>
<div id="map-container">

    <div id="map">
    </div>

    <div id="slider_control" :style="show_map_controls ? 'display: block': 'display: none'">
       <input type="range" class="slider" style="display:inline" name="time_window" v-model="time_window" min="1" max="365"><span>{{ time_window }}</span>
    </div>

    <div id="color_by_control" class="btn-group btn-group-toggle btn-group-xs" :style="show_map_controls ? 'display: block': 'display: none'">
        <div class="dropdown" style="display:inline">

            <template v-if="Object.keys(country_settings).length > 1">
                <button class="btn btn-secondary btn-sm dropdown-toggle btn-light" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img style="width:18px; height:18px" :src="country_settings[country]['img_url']">
                </button>
                <div class="dropdown-menu" style="min-width:30px" aria-labelledby="dropdownMenuButton">
                    <a
                        v-for="(value, key) in country_settings"
                        v-bind:key="key"
                        :title="value['name']"
                        v-on:click="setCountry(key)"
                        :style="country != key ? 'display:block': 'display:none'"
                        class="dropdown-item" href="#">
                            <img style="width:18px; height:18px" :src="value['img_url']">
                    </a>
                </div>
            </template>
        </div>
    
        <!--avg rssi-->
        <label title="Average RSSI" class="btn btn-light" :class="{'active': color_by == 'avg_rssi'}">
            <input type="radio" name="color_by" id="color_by_avg_rssi" value="avg_rssi" autocomplete="off" checked v-model="color_by">
            <img class="control-icon" src="/static/images/controls/avg_rssi.png">
        </label>
        <!--lq-->
        <label title="Link quality" class="btn btn-light" :class="{'active': color_by == 'link_quality'}">
            <input type="radio"  name="color_by" id="color_by_link_quality" value="link_quality" autocomplete="off" v-model="color_by">
            <img class="control-icon" src="/static/images/controls/link_quality.png">
        </label>
        <!--bs count-->
        <label title="Base station count" class="btn btn-light" :class="{'active': color_by == 'bs_count'}">
            <input type="radio" name="color_by" id="color_by_bs_count" value="bs_count" autocomplete="off" v-model="color_by">
            <img class="control-icon" src="/static/images/controls/bs_count.png">
        </label>
        <!--time_window-->
        <label title="Time window" class="btn btn-light" :class="{'active': color_by == 'time_window'}">
            <input type="radio" name="color_by" id="color_by_time_window" value="time_window" autocomplete="off" v-model="color_by">
            <img class="control-icon" src="/static/images/controls/time_window.png">
        </label>
        <!--sigfox_delta-->
        <label v-if="with_sigfox_delta" title="Sigfox coverage delta" class="btn btn-light" :class="{'active': color_by == 'sigfox_delta'}">
            <input type="radio" name="color_by" id="color_by_sigfox_delta" value="sigfox_delta" autocomplete="off" v-model="color_by">
            <img class="control-icon" src="/static/images/controls/sigfox_delta.png">
        </label>
      
        <!-- show base stations -->
        <label v-if="base_stations.length > 0" :title="show_base_stations ? 'Hide base stations' : 'Show base stations'" class="btn btn-light ml-2" :class="{'active': show_base_stations == true}">
            <input type="checkbox" autocomplete="off" v-model="show_base_stations">
            <img class="control-icon" src="/static/images/controls/show_base_stations.png">
        </label>

        <!-- show map legend -->
        <label :title="show_map_legend ? 'Hide map legend' : 'Show map legend'" class="btn btn-light" :class="{'active': show_map_legend == true}">
            <input type="checkbox" checked autocomplete="off" v-model="show_map_legend">
            <img class="control-icon" src="/static/images/controls/show_legend.png">
        </label>
        
    </div>

    <div id="search_control" :style="show_map_controls ? 'display: block': 'display: none'">
      <div class="form-group mb-1">
        <div class="input-group">
            <input id="location_search_input" class="form-control form-control-sm" type="text"/>
            <span class="input-group-append">
                <div class="input-group-text"><i class="fa fa-search"></i></div>
            </span>
        </div>
      </div>
    </div>

    <div id="legend" :style="show_map_legend && show_map_controls ? 'display: block': 'display: none'">
      <div v-if="color_by === 'link_quality'">
          <div class="legend-item"><span class="box" style="background-color:#00FF00"></span> Excellent</div>
          <div class="legend-item"><span class="box" style="background-color:#A8FF33"></span> Good</div>
          <div class="legend-item"><span class="box" style="background-color:#FFF933"></span> Average</div>
          <div class="legend-item"><span class="box" style="background-color:#FF0000"></span> Limit</div>
      </div>
      <div v-if="color_by === 'avg_rssi'">
          <div v-for="item in rssi_ranges" class="legend-item" v-bind:key="item.color">
              <span class="box" v-bind:style="{backgroundColor: item.color}"></span>
              <span v-if="item.from && item.to">{{ item.to }} to {{ item.from }}</span>
              <span v-else-if="item.from">&gt; {{ item.from }}</span>
              <span v-else-if="item.to">&le; {{ item.to }}</span>
          </div>
      </div>
      <div v-if="color_by === 'bs_count'">
          <div class="legend-item"><span class="box" style="background-color:#00FF00"></span> 3+ BS</div>
          <div class="legend-item"><span class="box" style="background-color:#FFFF00"></span> 2 BS</div>
          <div class="legend-item"><span class="box" style="background-color:#FF0000"></span> 1 BS</div>
      </div>
      <div v-if="color_by === 'time_window'">
          <div class="legend-item"><span class="box" style="background-color:#00FF00"></span> Yesterday</div>
          <div class="legend-item"><span class="box" style="background-color:#00FFFF"></span> 2-7 days ago</div>
          <div class="legend-item"><span class="box" style="background-color:#FFFF00"></span> 8-30 days ago</div>
          <div class="legend-item"><span class="box" style="background-color:#FF0000"></span> 31+ days ago</div>
      </div>
      <div v-if="color_by === 'sigfox_delta'">
          <div class="legend-item"><span class="box" style="background-color:#FF0000"></span> &lt; -20</div>
          <div class="legend-item"><span class="box" style="background-color:#FFFF00"></span> -20 to -10</div>
          <div class="legend-item"><span class="box" style="background-color:#00FF00"></span> -10 to 10</div>
          <div class="legend-item"><span class="box" style="background-color:#00FFFF"></span> &gt;= 10</div>
      </div>
    </div>

</div>
</template>

<script>
/*global google*/ 
/*global MarkerClusterer*/
/*global OverlappingMarkerSpiderfier*/

import { EventBus } from '../event-bus.js'

export default {
  name: 'GoogleMap',
  data () {
    return {
      country: this.country_prop, // country is initialized with the value of country_prop, which is passed from the parent (App) component
      vueGMap: null,
      markerClusterer: null,
      infowindow: null,
      precision: 0.01,
      markersHash: {},
      connectionLines: [],
      show_map_controls: false,
      show_base_stations: false,
      show_map_legend: true,
      color_by: 'avg_rssi',
      time_window: 365,
      agg_load_timer: null,
    }
  },
  computed: {
      rssi_ranges() {
          var rcz = this.country_settings[this.country]['rcz'];
          if ((rcz == 2) || (rcz == 4)) {
              return [
                {'from': -114, 'color': '#00FF00'},
                {'from': -127, 'to': -114, 'color': '#FFFF00'},
                {'to': -127, 'color': '#FF0000'},
              ]
          } else {
            return [
                {'from': -122, 'color': '#00FF00'},
                {'from': -135, 'to': -122, 'color': '#FFFF00'},
                {'to': -135, 'color': '#FF0000'},
            ]
          }
      }
  },
  methods: {
      setCountry: function(country) {
            this.country = country;
            this.vueGMap.panTo(this.country_settings[this.country]['map_center']);
            this.vueGMap.setZoom(this.country_settings[this.country]['map_zoom']);
        },
      loadGoogleMaps: function() {
          return new Promise((resolve, reject) => {
            let gmap = document.createElement('script');
            gmap.src = `https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyBfqdzmcDlG5JAVmJPcV40EndVvglYSMAk&libraries=places`;
            gmap.type = 'text/javascript';
            gmap.onload = resolve;
            gmap.onerror = reject;
            document.body.appendChild(gmap);
          })
      },
      loadMarkerClusterer: function() {
          return new Promise((resolve, reject) => {
            let mc = document.createElement('script');
            mc.src = `https://cdnjs.cloudflare.com/ajax/libs/markerclustererplus/2.1.4/markerclusterer.min.js`;
            mc.type = 'text/javascript';
            mc.onload = resolve;
            mc.onerror = reject;
            document.body.appendChild(mc);
          })
      },
      loadOverlappingMarkerSpiderfier: function() {
          return new Promise((resolve, reject) => {
            let mc = document.createElement('script');
            mc.src = `https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js`;
            mc.type = 'text/javascript';
            mc.onload = resolve;
            mc.onerror = reject;
            document.body.appendChild(mc);
          })
      },
      getAggregationColor: function(fieldValue, colorBy) {
            if (colorBy == 'link_quality') {
                switch(fieldValue) {
                    case "EXCELLENT":
                        return "#00FF00";
                    case "GOOD":
                        return "#A8FF33";
                    case "AVERAGE":
                        return "#FFF933";
                    case "LIMIT":
                        return "#FF0000";
                }
            } else if (colorBy == 'avg_rssi') {
                for (var i = 0; i < this.rssi_ranges.length; i++) {
                    var from = this.rssi_ranges[i]['from'];
                    var to = this.rssi_ranges[i]['to'];
                    from = (from !== undefined ? from : -9999);
                    to = (to !== undefined ? to : 9999);
                    if ((fieldValue > from) && (fieldValue <= to)) {
                        return this.rssi_ranges[i]['color'];
                    }
                }
                return '#000000'; // No range found.
            } else if (colorBy == 'bs_count') {
                if (fieldValue >= 3) {
                    return "#00FF00";
                } else if (fieldValue == 2) {
                    return "#FFFF00";
                } else if (fieldValue == 1) {
                    return "#FF0000";
                }
            } else if (colorBy == 'time_window') {
                if (fieldValue == 1) {
                    return '#00FF00';
                } else if (7 >= fieldValue && fieldValue > 1) {
                    return '#00FFFF';
                } else if (30 >= fieldValue && fieldValue > 7) {
                    return '#FFFF00';
                } else {
                    return '#FF0000';
                }
            } else if (colorBy == 'sigfox_delta') {
                if (fieldValue < -20) {
                    return '#FF0000';
                } else if (-20 <= fieldValue && fieldValue < -10) {
                    return '#FFFF00';
                } else if (-10 <= fieldValue && fieldValue < 10) {
                    return '#00FF00';
                } else { // >= 10
                    return '#00FFFF';
                }
            }
        },
      initGoogleMaps: function() {
          this.vueGMap = new google.maps.Map(document.getElementById('map'), {
                zoom: 6,
                center: {lat: 54, lng: 0},
                mapTypeId: google.maps.MapTypeId.SATELLITE,
                controlSize: 20,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DEFAULT,
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
                fullScreenControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
            });
          var map = this.vueGMap;
          var vm = this;

          this.infowindow = new google.maps.InfoWindow();

          this.markerClusterer = new MarkerClusterer(map, [], {imagePath: '/static/images/m', minimumClusterSize: 4, ignoreHidden: true});

          this.spiderfier = new OverlappingMarkerSpiderfier(map, { markersWontMove: true, markersWontHide: true });

          EventBus.$emit('fetch_aggregations', this.country);
          EventBus.$emit('fetch_base_stations', this.country);

          map.data.setStyle(function(feature) {  
              if (feature.getProperty('type')=='aggregation'){
                  var color_by = feature.getProperty('color_by');
                  var color = '#0000ff';
                  color = vm.getAggregationColor(feature.getProperty(color_by), color_by);
                  var visible = feature.getProperty('visible');
                  return { visible: visible, fillColor: color, strokeColor: color, strokeWeight: 1, fillOpacity: 0.8 };
              }
          });

          map.data.addListener('click', function(event) {
              var myHTML = 'Date: '+event.feature.getProperty('date')+'<br>';
              var sigfox_delta = null;
              if (event.feature.getProperty('sigfox_delta'))
                sigfox_delta = Math.round(event.feature.getProperty('sigfox_delta')*10)/10
              myHTML += 'BS count: '+event.feature.getProperty('bs_count')+'<br>';
              myHTML += 'Best server: '+event.feature.getProperty('best_server')+'<br>';
              myHTML += 'Avg RSSI: '+Math.round(100*event.feature.getProperty('avg_rssi'))/100+' dBm<br>';
              myHTML += 'Link quality: '+event.feature.getProperty('link_quality')+'<br>';
              if (vm.with_sigfox_delta) {
                myHTML += 'Sigfox delta: ' + sigfox_delta + ' dB';
              }
              if (vm.show_base_stations == true) {
                var base_stations = event.feature.getProperty('base_stations');
                var best_server = event.feature.getProperty('best_server');
                vm.removeConnectionLines();
                base_stations.forEach(function(bsid){
                    if (bsid in vm.markersHash) {
                        var marker = vm.markersHash[bsid];
                        var lineColor;
                        if (bsid === best_server)
                            lineColor = '#00FF00';
                        else
                            lineColor = '#FF0000';
                        var line_coordinates = [
                            {lat: event.feature.getProperty('lat') + vm.precision/2, lng: event.feature.getProperty('lng') + vm.precision/2},
                            {lat: marker.getPosition().lat(), lng: marker.getPosition().lng()},
                        ];
                        var line = new google.maps.Polyline({
                            path: line_coordinates,
                            geodesic: true,
                            strokeColor: lineColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            zIndex: 100
                        });

                        line.setMap(map);

                        vm.connectionLines.push(line);
                    }
                });
              }
              vm.infowindow.setContent("<div style='width:150px;'>"+myHTML+"</div>");
              // position the infowindow on the marker
              if ( event.feature.getProperty('type') == 'aggregation' )
                  vm.infowindow.setPosition(new google.maps.LatLng(event.feature.getProperty('lat') + vm.precision, event.feature.getProperty('lng')));
              vm.infowindow.open(map);
          });

          var legend = document.getElementById('legend');
          map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);

          var search_control = document.getElementById('search_control');
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(search_control);

          var color_by_control = document.getElementById('color_by_control');
          map.controls[google.maps.ControlPosition.LEFT_TOP].push(color_by_control);

          var slider_control = document.getElementById('slider_control');
          map.controls[google.maps.ControlPosition.LEFT_TOP].push(slider_control);

          var location_search_input = document.getElementById('location_search_input');
          var autocomplete = new google.maps.places.Autocomplete(location_search_input);
          autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

          vm.show_map_controls = true;

          autocomplete.addListener('place_changed', function() {
                vm.infowindow.close();
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(15); // 15 is an arbitrary choice.
            }
          });

          this.vueGMap.panTo(this.country_settings[this.country]['map_center']);
          this.vueGMap.setZoom(this.country_settings[this.country]['map_zoom']);

      },
      removeConnectionLines: function () {
        if (this.connectionLines.length > 0) {
            this.connectionLines.forEach(function(line) {
            line.setMap(null);
            });
            this.connectionLines.length = 0;
        }
      },
      processLargeArray: function(array, processElement) {
          var chunk = 100;
          var index = 0;
          var vm = this;
          clearTimeout(vm.agg_load_timer);
          function doChunk() {
              var cnt = chunk;
              while (cnt-- && index < array.length) {
                  processElement(array[index]);
                  ++index;
              }
              if (index < array.length) {
                  vm.agg_load_timer = setTimeout(doChunk, 2);
              }
          }    
          doChunk();
      },
  } ,
  mounted() {
    if (!(typeof google === 'object' && typeof google.maps === 'object')) {
    this.loadGoogleMaps()
        .then(this.loadMarkerClusterer)
        .then(this.loadOverlappingMarkerSpiderfier)
        .then(this.initGoogleMaps);
    } else {
      this.initGoogleMaps();
    }
  },
  watch: {
    aggregations: function(val) {
        var aggregations = val;
        var map = this.vueGMap;
        var vm = this;

        console.log(aggregations);
        map.data.forEach(function(feature) {
            map.data.remove(feature);
        });

        this.processLargeArray(aggregations, function (aggregation) {
            var coords = [
                {lat: aggregation.lat + vm.precision, lng: aggregation.lng}, // north west
                {lat: aggregation.lat, lng: aggregation.lng}, // south west
                {lat: aggregation.lat, lng: aggregation.lng + vm.precision}, // south east
                {lat: aggregation.lat + vm.precision, lng: aggregation.lng + vm.precision},  // north east
            ];

            var base_stations = aggregation.base_stations
            var best_server = aggregation.best_server

            if (vm.base_station_filter != null && ! base_stations.includes(vm.base_station_filter))
                return;

            map.data.add({properties: {
                type: 'aggregation',
                color_by: vm.color_by,
                lat: aggregation.lat,
                lng: aggregation.lng,
                bs_count: base_stations.length,
                base_stations: base_stations,
                best_server: best_server,
                avg_rssi: aggregation.avg_rssi,
                link_quality: aggregation.link_quality,
                date: aggregation.time,
                sigfox_delta: aggregation.sigfox_delta,
                time_window: aggregation.time_window,
                visible: (aggregation.time_window <= vm.time_window)
            }, geometry: new google.maps.Data.Polygon([coords])})
            
        });
        EventBus.$emit('loading_aggregations_done');
    },
    base_stations: function(val) {
        var base_stations = val;
        var map = this.vueGMap;
        var vm = this;

        console.log(base_stations);
        map.data.forEach(function(feature) {
            if( feature.getProperty('type') == 'point') {
                map.data.remove(feature);
            }
        });
        var baseStationMarkers = [];
        for (var i=0;i < base_stations.length; i++) {
                
                if (vm.base_station_filter != null && ! (base_stations[i].bsid === vm.base_station_filter))
                    return;
                
                var id = base_stations[i].id;
                var lat = base_stations[i].lat;
                var lng = base_stations[i].lng;

                var marker = new google.maps.Marker({
                position: {lat:lat,lng:lng},
                icon: {url: '/static/images/radio_tower.png', labelOrigin: new google.maps.Point(16,0)},
                visible: vm.show_base_stations,
                id: id,
                });

                marker.addListener('mouseover', function() {
                    var myHTML = '<b>' + this.id + '</b>';

                    vm.infowindow.setContent("<div style='width:150px;'>"+myHTML+"</div>");
                    vm.infowindow.open(map, this);
                });

                marker.addListener('click', function() {
                    var myHTML = '<b>' + this.id + '</b>';

                    vm.infowindow.setContent("<div style='width:150px;'>"+myHTML+"</div>");
                    vm.infowindow.open(map, this);
                });

                marker.addListener('mouseout', function() {
                    vm.infowindow.close();
                });
                
                baseStationMarkers.push(marker);
                vm.markersHash[marker.id] = marker;
        }
        this.markerClusterer.clearMarkers();
        this.markerClusterer.addMarkers(baseStationMarkers);
    },
    color_by: function(val) {
      var map = this.vueGMap;
      map.data.forEach(function(feature) {
          feature.setProperty('color_by', val);
      });
    },
    show_base_stations: function(val) {
      console.log("show_base_stations changed.")
      this.markerClusterer.getMarkers().forEach(function(marker) {
          marker.setVisible(val);
      });
      this.markerClusterer.repaint();
      this.removeConnectionLines();
    },
    country: function (val) {
          EventBus.$emit('change_country', val);
    },
    time_window: function(val) {
      var map = this.vueGMap;
      map.data.forEach(function(feature) {
          if (feature.getProperty('time_window') > val)
            feature.setProperty('visible', false);
        else {
            feature.setProperty('visible', true);
        }
      });
    }
  },
  
  props: ['aggregations', 'base_stations', 'base_station_filter', 'country_prop', 'country_settings', 'with_sigfox_delta']
}
</script>

<style>
#map-container {
    min-height: 100%;
    height:100%;
    width:100%;
}

#map {
    min-height: 100%;
    height: auto !important;
    height: 100%;
}

#legend {
    font-family: Arial, sans-serif;
    font-size: 10px;
    background: #fff;
    padding: 5px;
    border: 1px solid #000;
    margin-top: 0;
}

#location_search_input {
    width: 120px;
}
@media (min-width: 768px) {
    #location_search_input {
        width: 200px;
    }
}

.legend-item {
    margin:2px;
    text-align:left;
}

.box {
  float: left;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  clear: both;
}

.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.control-icon {
    width: 16px;
    height: 16px;
}

/* Slider */

#slider_control {
    width: 148px;
    background-color: white;
    margin-top: 2px;
}
.slider {
 -webkit-appearance: none;
 width: 80%;
 height: 7px;
 border-radius: 5px;
 background: #d3d3d3;
 outline: none;
 opacity: 0.7;
 -webkit-transition: .2s;
 transition: opacity .2s;
}

.slider:hover {
 opacity: 1;
}

.slider::-webkit-slider-thumb {
 -webkit-appearance: none;
 appearance: none;
 width: 12px;
 height: 12px;
 border-radius: 50%;
 background: #4CAF50;
 cursor: pointer;
}

.slider::-moz-range-thumb {
 width: 12px;
 height: 12px;
 border-radius: 50%;
 background: #4CAF50;
 cursor: pointer;
}
</style>